<template>
	<div id="box">
		<div class="box">
			<div id="map" class="map"></div>
      <frimpup :frimshow="frimshow" :frimobj="frimobj" @frimswitch="firmsh"></frimpup>
      <div class="top">
          <el-input
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="kw">
          </el-input>
          <el-button>搜索</el-button>
          <div class="pianqu">
            <p class="title" @click="activequ(1)" @mouseover="ins">产业片区<img src="../../assets/img/片区.png" /></p>
            <div class="contentbox" v-if="pianqushow" @mouseleave="out">
               <p v-for="(item,i) in pianqulist" :key="i" @click="pianquitem(item.codeValue,i)">{{item.codeLabel}}</p>
            </div>
          </div>
          <div class="yuanqu">
            <p class="title" @click="quanguo">全国百强<img src="../../assets/img/园区1.png" /></p>
          </div>
          <div class="leixing">
            <p class="title" @click="activequ(3)"  @mouseover="leiin">全国基地<img src="../../assets/img/产业类型.png" /></p>
            <div class="contentbox" v-if="leixingshow" @mouseleave="leiout">
               <p @click="jidi(1)">广东庵埠</p>
               <p @click="jidi(2)">浙江龙港</p>
               <p @click="jidi(3)">安徽桐城</p>
               <p @click="jidi(4)">河北雄县</p>
            </div>
          </div>
          <!-- <div class="leixing">
            <p class="title">数据分析<img src="../../assets/img/数据分析.png" /></p>
          </div> -->
      </div>
      <el-dialog
        :title="pianqudetal.industrialAreaName"
        :visible.sync="dialogVisible"
        close-on-press-escape
        top="20vh"
        width="300px"
        >
        <div class="detalitem">
          <p>产值(万元)</p>
          <p>{{pianqudetal.productionCount?pianqudetal.productionCount:'暂无信息'}}</p>
        </div>
        <div class="detalitem">
          <p>税收(万元)</p>
          <p>{{pianqudetal.taxCount?pianqudetal.taxCount:'暂无信息'}}</p>
        </div>
        <div class="detalitem">
          <p>企业数量</p>
          <p>{{pianqudetal.industrialCompanyCount?pianqudetal.industrialCompanyCount:'暂无信息'}}</p>
        </div>
        <div class="detalitem">
          <p>规上企业数</p>
          <p>{{pianqudetal.upRuleCount?pianqudetal.upRuleCount:'暂无信息'}}</p>
        </div>
        <div class="detalitem">
          <p>规下企业数</p>
          <p>{{pianqudetal.downRuleCount?pianqudetal.downRuleCount:'暂无信息'}}</p>
        </div>
        <div class="detalitem">
          <p>规上企业A类数</p>
          <p>{{pianqudetal.upRuleACount?pianqudetal.upRuleACount:'暂无信息'}}</p>
        </div>
        <div class="detalitem">
          <p>规上企业B类数</p>
          <p>{{pianqudetal.upRuleBCount?pianqudetal.upRuleBCount:'暂无信息'}}</p>
        </div>
      </el-dialog>
      <div class="rightpop" v-if="false" :style="{'transform':transform,}">
          <p class="comeright" @click="comeR">向右收起&gt;</p>
          <img class="tuila"  v-show="transform!='translateX(0px)'" src="../../assets/img/推拉.png" @click="comeL"/>
          <div class="poptop">
            <h1>产业园区基础信息</h1>
            <div class="popbox">
              <div class="popitem">
                <p>园区</p>
                <p>{{chanyeyuanqu.projectCount?chanyeyuanqu.projectCount:'暂无数据'}}<span>{{chanyeyuanqu.projectCount?'个':''}}</span></p>
              </div>
              <div class="popitem">
                <p>占地</p>
                <p>{{chanyeyuanqu.areaCount?chanyeyuanqu.areaCount:'暂无数据'}}<span>{{chanyeyuanqu.areaCount?'亩':''}}</span></p>
              </div>
              <div class="popitem">
                <p>企业</p>
                <p>{{chanyeyuanqu.companyCount?chanyeyuanqu.companyCount:'暂无数据'}}<span>{{chanyeyuanqu.companyCount?'家':''}}</span></p>
              </div>
              <div class="popitem">
                <p>员工</p>
                <p>{{chanyeyuanqu.empCount?chanyeyuanqu.empCount:'暂无数据'}}<span>{{chanyeyuanqu.empCount?'人':''}}</span></p>
              </div>
              <div class="popitem">
                <p>龙头企业</p>
                <p>{{chanyeyuanqu.leadingCount?chanyeyuanqu.leadingCount:'暂无数据'}}<span>{{chanyeyuanqu.leadingCount?'家':''}}</span></p>
              </div>
              <div class="popitem">
                <p>科技企业</p>
                <p>{{chanyeyuanqu.stCompanyCount?chanyeyuanqu.stCompanyCount:'暂无数据'}}<span>{{chanyeyuanqu.stCompanyCount?'家':''}}</span></p>
              </div>
            </div>
          </div>
          <div class="poppie">
            <h1>企业产值信息</h1>
            <div class="piebox">
                <div class="pieitem">
                  <el-progress type="dashboard" :width="width" :percentage="chanzhinum" :color="colors"></el-progress>
                  <p>产值</p>
                  <p>{{chanyeyuanqu.productionCount?chanyeyuanqu.productionCount+'万元':'暂无数据'}}</p>
                </div>
                <div class="pieitem">
                  <el-progress type="dashboard" :width="width"  :percentage="shuishounum" :color="colors"></el-progress>
                  <p>税收</p>
                  <p>{{chanyeyuanqu.taxCount?chanyeyuanqu.taxCount+'万元':'暂无数据'}}</p>
                </div>
                <div class="pieitem">
                  <el-progress type="dashboard" :width="width"  :percentage="zengzhinum" :color="colors"></el-progress>
                  <p>增加值</p>
                  <p>{{chanyeyuanqu.productionIncrease?chanyeyuanqu.productionIncrease+'万元':'暂无数据'}}</p>
                </div>
            </div>
          </div>
          <div class="firmtype">
            <h1>企业类型信息</h1>
            <div class="firmitem">
              <img src="../../assets/img/五角星.png" >
              <div>
                <p>工业企业数量</p>
                <p>{{chanyeyuanqu.industrialCompanyCount?chanyeyuanqu.industrialCompanyCount+'家':'暂无数据'}}</p>
              </div>
            </div>
            <div class="firmitem">
              <img src="../../assets/img/五角星.png" >
              <div>
                <p>规上企业数</p>
                <p>{{chanyeyuanqu.upRuleCount?chanyeyuanqu.upRuleCount+'家':'暂无数据'}}</p>
              </div>
            </div>
            <div class="firmitem">
              <img src="../../assets/img/五角星.png" >
              <div>
                <p>规下企业数</p>
                <p>{{chanyeyuanqu.downRuleCount?chanyeyuanqu.downRuleCount+'家':'暂无数据'}}</p>
              </div>
            </div>
            <div class="firmitem">
              <img src="../../assets/img/五角星.png" >
              <div>
                <p>规上企业A类数</p>
                <p>{{chanyeyuanqu.upRuleACount?chanyeyuanqu.upRuleACount+'家':'暂无数据'}}</p>
              </div>
            </div>
            <div class="firmitem">
              <img src="../../assets/img/五角星.png" >
              <div>
                <p>规上企业B类数</p>
                <p>{{chanyeyuanqu.upRuleBCount?chanyeyuanqu.upRuleBCount+'家':'暂无数据'}}</p>
              </div>
            </div>
          </div>
      </div>
		</div>
	</div>
</template>

<script>
  import frimpup from "@/components/firmpup"
	import {queryTop,queryCompany,parksInfo,queryIndustrial,tenantBusiness} from"@/api/Amap/Amap"
	export default {
		data() {
			return {
				Amap: null,
				markerList:[],
        markerList2:[],
        kw:null,
        pianqushow:false,
        leixingshow:false,
        pianqulist:[],
        pianqudetal:{},
        pianquid:null,
        dialogVisible:false,
        percentage: 10,
        colors: [
          {color: '#f56c6c', percentage: 20},
          {color: '#e6a23c', percentage: 40},
          {color: '#5cb87a', percentage: 60},
          {color: '#1989fa', percentage: 80},
          {color: '#6f7ad3', percentage: 100}
        ],
        width:95,
        transform:"translateX(300px)",
        chanyeyuanqu:{
          projectCount:null,
          areaCount:null,
          companyCount:null,
          empCount:null,
          leadingCount:null,
          stCompanyCount:null,
          productionCount:null,
          taxCount:null,
          productionIncrease:null,
          industrialCompanyCount:null,
          upRuleCount:null,
          downRuleCount:null,
          upRuleACount:null,
          upRuleBCount:null,
        },
        chanzhinum:0,
        shuishounum:0,
        zengzhinum:0,
        frimshow:false,
        frimobj:{},
        activeid:null,
			}
		},
		mounted() {
			var _this=this
      this.chanyepianqu()
			//this.Amap=new AMap.Map("map")
      _this.Amap = new AMap.Map('map', {
        center: [120.552952,27.578379],//中心点坐标
      });
			this.Amap.setMapStyle("amap://styles/blue")
      this.Amap.setCenter([120.552952,27.578379])
      this.Amap.setZoom(14)

			this.xinzheng("龙港市")
      this.first("rgba(255, 240, 23, 1.0)")
		},
		methods:{
      firmsh(val){
        this.frimshow=val
      },
      xinzheng(name){
        var _this=this
        this.Amap.plugin([
          'AMap.DistrictSearch'
        ], function(){
        	// 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
          var districtSearch = new AMap.DistrictSearch({
            // 返回行政区边界坐标等具体信息
            extensions: 'all',
            // 设置查询行政区级别为 区
            level: 'district'
          })
          // 搜索所有省/直辖市信息
          districtSearch.search(name, function(status, result) {
            // 获取朝阳区的边界信息
            var bounds = result.districtList[0].boundaries
            //console.log("districtSearch",bounds)
            var polygons = []
            if (bounds) {
              for (var i = 0, l = bounds.length; i < l; i++) {
                //生成行政区划polygon
                var polygon = new AMap.Polygon({
                  map: _this.Amap,
                  strokeWeight: 1,
                  path: bounds[i],
                  fillOpacity: 0.7,
                  fillColor: 'rgba(57,231,15,0.2)',
                  strokeColor: 'rgb(10,178,243)'
                })
                polygons.push(polygon)
              }
              if(_this.activeid==3){

              }else{
                // 地图自适应
                _this.Amap.setFitView()
              }
            }
          })
        })
      },
      comeL(){
        this.transform="translateX(0px)"
      },
      comeR(){
        this.transform="translateX(300px)"
      },
      quanguo(){
        this.pianquid=null
        this.Amap.clearMap()
        this.markerList=[]
        this.Amap.setCenter([109.02618,34.103966])        this.Amap.setZoom(5)
        var _this=this
		queryTop().then(res=>{
			console.log(res)
			if(res.data.data){
			  var arr=res.data.data
			  arr.forEach(item=>{
			    var obj=new AMap.CircleMarker({
			    	center:new AMap.LngLat(item.longitude*1,item.latitude*1),
			    	fillColor:'#00ffff',
			      radius:10,
			    	extData:{
			    		"id":item.tenantId,
			        "tenantName":item.tenantName
			    	}
			    })
			    obj.on('click',e=>{
			      _this.activedian(obj.getExtData().id,obj.getExtData().tenantName)
			    })
			    _this.markerList.push(obj)
			  })
			  _this.Amap.add(_this.markerList)
			}
		})
      },
       first(color){
         var _this=this
			queryCompany(this.kw,this.pianquid).then(res=>{
            if(res.data.data){
              var arr=res.data.data
              arr.forEach(item=>{
                var obj=new AMap.CircleMarker({
                	center:new AMap.LngLat(item.longitude*1,item.latitude*1),
                	fillColor:color,
                  radius:10,
                	extData:{
                		"id":item.tenantId,
                    "tenantName":item.tenantName
                	}
                })
                obj.on('click',e=>{
                  _this.activedian(obj.getExtData().id,obj.getExtData().tenantName)
                })
                _this.markerList.push(obj)
              })
              if(!_this.pianquid){
                _this.markerList2=_this.markerList
                 _this.Amap.add(_this.markerList)
              }else{
                _this.markerList=_this.markerList.concat(_this.markerList2)
                _this.Amap.add(_this.markerList)
              }
            }
          })
          parksInfo().then(res=>{
            if(res.data.data){
              _this.chanyeyuanqu=res.data.data
              let a=res.data.data.productionCount?res.data.data.productionCount:0
              let b=res.data.data.taxCount?res.data.data.taxCount:0
              let c=res.data.data.productionIncrease?res.data.data.productionIncrease:0
              let count =a*1+b*1+c*1
              _this.chanzhinum=res.data.data.productionCount?(res.data.data.productionCount/count).toFixed(2)*100:0
              _this.shuishounum=res.data.data.taxCount?(res.data.data.taxCount/count).toFixed(2)*100:0
              _this.zengzhinum=res.data.data.productionIncrease?(res.data.data.productionIncrease/count).toFixed(2)*100:0
            }
          })
       },
       chanyepianqu(){
         queryIndustrial().then(res=>{
           if(res.data.data){
             this.pianqulist=res.data.data
           }
         })
       },
       activedian(id,name){
		 tenantBusiness(id).then(res=>{
           this.frimshow=true
           if(res.data.data){
             res.data.data.tenantName=name
              res.data.data.foundDate=res.data.data.foundDate?this.$moment(res.data.data.foundDate).format('YYYY-MM-DD'):null
              this.frimobj=res.data.data
           }
         })
       },
       activequ(val){
         if(val==1){
           this.activeid=1
            this.Amap.clearMap()
            this.pianqushow=true
            this.kw=""
            this.pianquid=null
            this.first('yellow')
            this.xinzheng("龙港市")
           this.leixingshow=false
         }else if(val==3){
           this.activeid=3
           this.leixingshow=true
           this.pianqushow=false
           this.Amap.setZoom(5)
           this.Amap.clearMap()
           this.markerList=[]
           this.xinzheng("潮安区")
           this.xinzheng("龙港市")
           this.xinzheng("桐城市")
           this.xinzheng("雄县")
           var location=[{lod:116.676971,lat:23.463598},{lod:120.552952,lat:27.578379},{lod:116.936588,lat:31.035476},{lod:116.108624,lat:38.994825}]
           var arr=[]
          location.forEach(item=>{
              var obj=new AMap.CircleMarker({
                center:new AMap.LngLat(item.lod*1,item.lat*1),
                fillColor:'#ff5500',
                 radius:10,
               })
               arr.push(obj)
          })
          this.Amap.add(arr)
         }else{
           this.activeid=null
         }
       },
       jidi(val){
         this.Amap.setZoom(11)
          var location=[{lod:116.676971,lat:23.463598},{lod:120.552952,lat:27.578379},{lod:116.936588,lat:31.035476},{lod:116.108624,lat:38.994825}]
          if(val==1){
             this.Amap.setCenter([116.676971,23.463598])
          }else if(val==2){
            this.Amap.setCenter([120.552952,27.578379])
          }else if(val==3){
            this.Amap.setCenter([116.936588,31.035476])
          }else if(val==4){
            this.Amap.setCenter([116.108624,38.994825])
          }
       },
       ins(){
         // this.pianqushow=true
         if(this.activeid==1){
           this.pianqushow=true
         }
       },
       leiin(){
         // this.leixingshow=true
         if(this.activeid==3){
           this.leixingshow=true
         }
       },
       out(){
         this.pianqushow=false
       },
       leiout(){
         this.leixingshow=false
       },
       pianquitem(id,index){
         this.pianquid=id
         this.Amap.remove(this.markerList)
         this.markerList=[]
         let colors=[{color: '#f56c6c'},{color: '#e6a23c'},{color: '#5cb87a'},{color: '#1989fa'},{color: '#6f7ad3'}]
         this.first(colors[index].color)
         // this.$axios.get("/apg/industry/industrialArea?industrialAreaId="+id).then(res=>{
         //  // console.log(res)
         //    this.pianqudetal=res.data.data
         //    this.dialogVisible=true
         // })
       },
       //在指定位置打开信息窗体
       openInfo(map,point,item) {
         //构建信息窗体中显示的内容
         var info = [];
         info.push("<div class='input-card content-window-card'>");
         info.push("<div style=\"padding:0px 0px 0px 0px;\"><h4>"+item.tenantName+"</h4>");
         info.push("<p class='input-item'></p>");
         info.push("<p class='input-item'>地址 :</p></div></div>");
         var infoWindow = new AMap.InfoWindow({
           content: info.join("")  //使用默认信息窗体框样式，显示信息内容
         });

         infoWindow.open(map,point);
       },
		},
    components:{
      frimpup
    }
	}
</script>

<style scoped="scoped" lang="less">



  .content-window-card{
    position: relative;
    width: 23rem;
    padding: 0.75rem 0 0 1.25rem;
    box-shadow: none;
    bottom: 0;
    left: 0;
  }

  .content-window-card p{
    height: 2rem;
  }
	#box{
		padding: 0 !important;
    overflow: hidden;
	}
	.box{
		width: 100%;
		height: 780px;
		background-color: #f2f3f5;
		border-radius: 4px;
    position: relative;
		.map{
			width: 100%;
			height: 100%;
		}
    .marker-route{
      width: 200px;
      background-color: #fff;
      text-align: center;
      font-size: 14px;
    }
    .top{
      width: 100%;
      height: 100px;
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      .el-input {
          width: 21%;
          margin-left: 10%;
      }
      button{
        margin-left: 20px;
      }
      .pianqu,.yuanqu,.leixing{
          width: 127px;
          margin-left: 20px;
          // background-color: #0B7EE9;
          background-image: -webkit-linear-gradient(left,#0B7EE9,#0B7EE9,#0B7EE9,#000001);
          background-image: -o-linear-gradient(right,#0B7EE9,#0B7EE9,#0B7EE9,#000001);
          background-image: -moz-linear-gradient(right,#0B7EE9,#0B7EE9,#0B7EE9,#000001);
          background-image: linear-gradient(to right,#0B7EE9,#0B7EE9,#0B7EE9,#000001);
          border-radius: 4px;
          position: relative;
          .title{
            width: 100%;
            height: 50px;
            text-align: center;
            font-size: 18px;
            color: #fff;
            line-height: 50px;
            font-weight: bold;
            display: flex;
            align-items: center;
            padding-left: 10px;
            img{
              width: 30px;
              margin-left: 10px;
            }
          }
          .title:hover{
            cursor: pointer;
          }
          .contentbox{
            width: 100%;
            position: absolute;
            background-color: #044E7D;
            border-radius: 4px;
            padding: 4px;
            p{
              width: 100%;
              height: 30px;
              line-height: 22px;
              color: #FFFFFF;
              padding: 4px;
            }
            p:hover{
              cursor: pointer;
              background-color: #02A7F0;
              border-radius: 4px;
            }
          }
      }
    }
	}
  .detalitem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    color: #FFFFFF;
  }
  .box/deep/.el-dialog{
    background-color: #074A88;
  }
  .box/deep/.el-dialog__title{
    color: #FFFFFF;
    font-weight: bold;
  }
  .rightpop{
    width: 300px;
    position: absolute;
    height: 650px;
    right: 0;
    top: 121px;
    // bottom: 0;
    // margin: auto 0;
    background-color: rgba(12,52,88,0.8);
    color: #FFFFFF;
    padding: 4px;
    border-radius: 4px;
    transition: all 0.5s;
    .comeright{
      position: absolute;
      right: 4px;
      top: 4px;
    }
    .tuila{
      position: absolute;
      top: 0;
      bottom: 0;
      left: -35px;
      margin: auto 0;
      width: 50px;
      height: 50px;
    }
    .tuila:hover{
      cursor: pointer;
    }
    .comeright:hover{
      cursor: pointer;
    }
    .poptop{
      margin-top: 10px;
    }
    .popbox{
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .popitem{
        width: 50%;
        height: 50px;
        display: flex;
        align-items: center;
        p:nth-child(1){
          width: 33px;
          margin-right: 10px;
        }
        p:nth-child(2){
          color: #07FAD8;
          span{
            color: #FFFFFF;
          }
        }
      }
    }
    .poppie{
      margin: 10px 0;
      color: #FFFFFF;
      .piebox{
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        .pieitem{
          text-align: center;
          p:nth-child(2){
            margin-bottom: 10px;
            font-weight: bold;
          }
        }
        .pieitem/deep/.el-progress__text{
          color: #FFFFFF;
        }
      }
    }
    .firmtype{
      margin-bottom: 10px;
      color: #FFFFFF;
      .firmitem{
        width: 100%;
        height: 40px;
        display: flex;
        margin-top: 5px;
        align-items: center;
        border-bottom: 1px solid #053D7E;
        img{
          width: 30px;
          margin: 0 30px;
        }
        div{
          font-size: 14px;
          p:nth-child(2){
            color: #B4E4FF;
          }
        }
      }
    }
  }


</style>
