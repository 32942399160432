<template>
  <div class="bos">
      <el-dialog
        :title="frimobj.tenantName?frimobj.tenantName:'企业基础信息'"
        :visible.sync="dialogVisible"
        width="800px"
        @close="handleClose"
        >
        <div class="boss">
          <div>
            <p>法定代表人</p>
            <p>{{frimobj.legalPerson?frimobj.legalPerson:'暂无数据'}}</p>
            <p>统一社会信用代码</p>
            <p>{{frimobj.creditCode?frimobj.creditCode:'暂无数据'}}</p>
          </div>
          <div>
            <p>营业状态</p>
            <p>{{frimobj.operateState?frimobj.operateState:'暂无数据'}}</p>
            <p>成立日期</p>
            <p>{{frimobj.foundDate?frimobj.foundDate:'暂无数据'}}</p>
          </div>
          <div>
            <p>企业类型</p>
            <p>{{frimobj.companyType?frimobj.companyType:'暂无数据'}}</p>
            <p>注册资本</p>
            <p>{{frimobj.registedCapital?frimobj.registedCapital:'暂无数据'}}</p>
          </div>
          <div>
            <p>参保人数</p>
            <p>{{frimobj.insuredNum?frimobj.insuredNum:'暂无数据'}}</p>
            <p>实缴资本</p>
            <p>{{frimobj.actualCapital?frimobj.actualCapital:'暂无数据'}}</p>
          </div>
          <div>
            <p>纳税人资质</p>
            <p>暂无数据</p>
            <p>行业</p>
            <p>{{frimobj.industry?frimobj.industry:'暂无数据'}}</p>
          </div>
          <div class="last">
            <p>联系电话</p>
            <p>暂无数据</p>
            <p>注册地址</p>
            <p class="xxx">{{frimobj.companyAddress?frimobj.companyAddress:'暂无数据'}}</p>
          </div>
          <div class="last">
            <p>经营范围</p>
            <p class="bom">{{frimobj.businessScope?frimobj.businessScope:'暂无数据'}}</p>
          </div>
        </div>
      </el-dialog>
  </div>
</template>

<script>
  export default {
    props:["frimshow","frimobj"],
    data() {
      return {
        dialogVisible:false,
        frim:{},
      }
    },
    methods:{
      handleClose(){
        this.dialogVisible=false
        this.$emit("frimswitch",this.dialogVisible)
      }
    },
    watch:{
      frimshow(val){
        this.dialogVisible=val
      },
      frimobj(val){
        console.log(val)
        this.firm=val
      }
    }
  }
</script>

<style scoped="scoped" lang="less">
  .boss{
    width: 100%;
    div{
      width: 100%;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      height: 30px;
      p{
        height: 100%;
        line-height: 30px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      p:nth-child(1){
        width:10% ;
      }
      p:nth-child(2){
        width:30% ;
      }
      p:nth-child(3){
        width:16% ;
      }
      p:nth-child(4){
        width:44% ;
      }
      .bom{
          width: 90% !important;
          overflow: unset;
          white-space: unset;
          height: auto;
      }
      .xxx{
        overflow: unset;
        white-space: unset;
        height: auto;
      }
    }
    .last{
      height: auto;
    }
  }
  .bos/deep/.el-dialog {
        background-color: #074A88;
  }
  .bos/deep/.el-dialog__title{
    color: #FFFFFF !important;
  }
</style>
