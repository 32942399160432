import fetch from '@/api/fetch'

// 全国百强
export function queryTop() {
  return fetch({
    url: '/apg/industry/queryTop',
    method: 'get'
  })
}
//  first
export function queryCompany(kw,industrialArea) {
   const data = {kw,industrialArea}
   return fetch({
     url: '/apg/industry/queryCompany',
     method: 'post',
     data
   })
}
// 
export function parksInfo() {
  return fetch({
    url: '/apg/industry/parksInfo',
    method: 'get'
  })
}
// 
export function queryIndustrial() {
  return fetch({
    url: '/apg/industry/queryIndustrial',
    method: 'get'
  })
}
export function tenantBusiness(tenantId) {
   const data = {tenantId}
   return fetch({
     url: '/api/api/rest/company/gov/tenantBusiness',
     method: 'post',
     data
   })
}
